import React, { useState, useCallback, useEffect } from 'react';
import ProductCard from './ProductCard';
import products from '../data/products';
import SearchBar from './SearchBar';
import '../styles/ProductList.css';
import socket from '../websocket/socket';
import Loader from './Loader';


interface Product {
  id: number;
  name: string;
  description: {
    description: string;
    name: string;
  };
  imageUrl: string;
  link: string;
  image: string;
  code: number;
  productSocialNetworks: ProductSocialNetwork[];
  socialLinks: {
    youtube: string;
    tiktok: string;
    instagram: string;
  };
}

interface ProductSocialNetwork {
  id: number;
  link: string;
  socialNetwork: SocialNetwork;
}

interface SocialNetwork {
  id: number;
  name: string;
  image: string;
}

const ProductList: React.FC = () => {
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [results, setResults] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    socket.on('findAllProductResults', (results: any) => {
      // socket.on('findAllProductResults', (results: Product[]) => {
      console.log(results);
      setResults(results);
      setFilteredProducts(results["body"])
      setLoading(false);
    });

    return () => {
      socket.off('findAllProductResults');
    };
  }, []); 

  const handleSearch = useCallback((code: string) => {
    
    // const filtered = products.filter(product => 
    //   product.code.toString().includes(code)
    // );
    // setFilteredProducts(filtered);

    // return () => {
    //   socket.off('findAllProductResults');
    // };
  }, []);

  return (
    <div>
      <SearchBar onSearch={handleSearch} setLoading={setLoading}/>
      <div className='center'>
        {loading && <Loader/>}
      </div>
      <div className="product-list">
        
        {filteredProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
