import React, { useEffect } from 'react';
import Header from './components/Header';
import ProductList from './components/ProductList';
import './styles/App.css';
import { RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { LanguageProvider } from './contexts/LanguageContext';
// import { initGA, logPageView } from './analytics';

const languageDetector = () => {
  const path = window.location.pathname.split('/');
  const lang = path[1];
  if (['ua', 'ru'].includes(lang)) {
    i18n.changeLanguage(lang);
    return lang;
  } else {
    i18n.changeLanguage('ua');
    return 'ua';
  }
};

const lang = languageDetector();

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App">
        <Header />
        <ProductList />
      </div>
    ),
  },
  {
    path: "/:lng",
    element: (
      <div className="App">
        <Header />
        <ProductList />
      </div>
    ),
  },
  {
    path: "/product/:id",
    element: (
      <div className="App">
        <Header />
        <Header />
      </div>
    ),
  },
  {
    path: "/:lng/product/:id",
    element: (
      <div className="App">
        <Header />
        <ProductList />
      </div>
    ),
  },
]);

function App() {
  return (
  <I18nextProvider i18n={i18n}>
    <LanguageProvider>
      <RouterProvider router={router} />
    </LanguageProvider>
  </I18nextProvider>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <Header />
//       <ProductList />
//     </div>
//   );
// }

export default App;
