import React from 'react';
import '../styles/ProductCard.css';
import { useTranslation } from 'react-i18next';

interface Product {
  id: number;
  name: string;
  description: {
    description: string;
    name: string;
  };
  imageUrl: string;
  link: string;
  image: string;
  code: number;
  productSocialNetworks: ProductSocialNetwork[];
  socialLinks: {
    youtube: string;
    tiktok: string;
    instagram: string;
  };
}

interface ProductSocialNetwork {
  id: number;
  link: string;
  socialNetwork: SocialNetwork;
}

interface SocialNetwork {
  id: number;
  name: string;
  image: string;
}

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { t } = useTranslation();
  
  return (
    <div className="product-card">
      {product.description && (
        <>
          <img src={product.image} alt={product.description.name} />
          <h2>{product.description.name}</h2>
          <p>{product.description.description}</p>
        </>
      )}
      <p>Код: {product.code}</p>
      <a href={product.link} target="_blank" rel="noopener noreferrer">{t('product_redirect_to_aliexpress')}</a>
      <div className="social-links">
        {product.productSocialNetworks.map(productSocialNetwork => (
          <a href={productSocialNetwork.link} target="_blank" rel="noopener noreferrer">
            <img src={productSocialNetwork.socialNetwork.image} alt={productSocialNetwork.socialNetwork.name} />
          </a>
        ))}
      </div>
    </div>
  );
}

export default ProductCard;
