interface Product {
  id: number;
  name: string;
  description: {
    description: string;
    name: string;
  };
  imageUrl: string;
  link: string;
  image: string;
  code: number;
  productSocialNetworks: ProductSocialNetwork[];
  socialLinks: {
    youtube: string;
    tiktok: string;
    instagram: string;
  };
}

interface ProductSocialNetwork {
  id: number;
  link: string;
  socialNetwork: SocialNetwork;
}

interface SocialNetwork {
  id: number;
  name: string;
  image: string;
}

const products: Product[] = [
  // {
  //   id: 1,
  //   name: 'Товар 1',
  //   description: {
  //     description: "",
  //     name: ""
  //   },
  //   imageUrl: 'посилання_на_зображення_товару_1',
  //   link: 'посилання_на_товар_1',
  //   code: 12345,
  //   socialLinks: {
  //     youtube: 'https://www.youtube.com/example1',
  //     tiktok: 'https://www.tiktok.com/@example1',
  //     instagram: 'https://www.instagram.com/example1',
  //   },
  // },
  // {
  //   id: 2,
  //   name: 'Товар 2',
  //   description: {
  //     description: "",
  //     name: ""
  //   },
  //   imageUrl: 'посилання_на_зображення_товару_2',
  //   link: 'посилання_на_товар_2',
  //   code: 67890,
  //   socialLinks: {
  //     youtube: 'https://www.youtube.com/example2',
  //     tiktok: 'https://www.tiktok.com/@example2',
  //     instagram: 'https://www.instagram.com/example2',
  //   },
  // },
  // Додайте більше товарів за потреби
];

export default products;
