import React, { useEffect } from 'react';
import '../styles/Header.css';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return <header>
    <h1>{t('title')}</h1>
  </header>
};

export default Header;
