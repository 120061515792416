import React, { useState, useEffect, ChangeEvent } from 'react';
import '../styles/SearchBar.css';
import socket from '../websocket/socket';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageContext';

interface SearchBarProps {
  onSearch: (code: string) => void;
  setLoading: (loading: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, setLoading }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [searchCode, setSearchCode] = useState('');
  const [query, setQuery] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    socket.emit('search', { query: newQuery });
  };

  useEffect(() => {
    onSearch(searchCode);
    socket.emit('findAllProduct', { code: searchCode, languageCode: language });
    
  }, [searchCode, language]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCode(event.target.value);
    setLoading(true)
    socket.emit('findAllProduct', { code: event.target.value, languageCode: language });
  };

  return (
    <div className="search-bar">
      {/* <div>{language}</div> */}
      <input
        type="text"
        value={searchCode}
        onChange={handleChange}
        placeholder={t("product_code")}
      />
    </div>
  );
};

export default SearchBar;
