import { io } from 'socket.io-client';

// const socket = io('http://localhost:3005');
const socket = io('https://api.dipper.igvend.com', {
// const socket = io('http://localhost:3005', {
    // transports: ['polling', 'websocket'],
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    timeout: 20000,
    // pingInterval: 25000,  
    // pingTimeout: 20000,
  });

  socket.on('connect', () => {
    console.log('Connected to server');
  });
  
  socket.on('disconnect', (reason) => {
    console.log(`Disconnected: ${reason}`);
  });
  
  socket.on('connect_error', (error) => {
    console.error(`Connection error: ${error}`);
  });

export default socket;